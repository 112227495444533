import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import HoursSpinner from "../../../../components/inputs/HoursSpinner";
import { RootState } from "../../../../store";

interface EstimatedLaborsProps {
  handlePropertyChange: (estimatedCost: Partial<ApiRequest>) => void;
  isCostSection?: boolean;
  hideLabel?: boolean;
  hideWidth?: boolean;
}

export const EstimateLabors: FC<EstimatedLaborsProps> = ({
  handlePropertyChange,
  isCostSection,
  hideLabel,
  hideWidth,
}) => {
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const { request, formIsLocked } = useSelector(
    (state: RootState) => state.requestForm
  );

  const handleHoursBlur = useCallback(
    (estimatedHours: number) => {
      if (estimatedHours !== request?.estimatedHours) {
        handlePropertyChange({ estimatedHours });
      }
    },
    [handlePropertyChange, request]
  );

  return (
    <FormControl id="estimatedHours">
      <Flex
        flexDirection={isCostSection ? "column" : "row"}
        mt={isMobile && isCostSection ? 4 : 0}
      >
        {!hideLabel && (
          <FormLabel htmlFor="estimatedHours">
            <Text size="md">Estimated Hours</Text>
          </FormLabel>
        )}
        <Spacer />
        <Box
          w={
            !hideWidth
              ? isMobile && isCostSection
                ? "100%"
                : "160px"
              : undefined
          }
          textAlign="right"
          alignItems="right"
        >
          <HoursSpinner
            key={`${request?.estimatedHours || 0}`}
            value={request?.estimatedHours || 0}
            onBlur={handleHoursBlur}
            isDisabled={formIsLocked}
            placeholder="Ex. 2h 30m"
          />
        </Box>
      </Flex>
    </FormControl>
  );
};
