import * as yup from "yup";

export const InventoryRequestFormSchema = yup.object().shape({
  requester: yup.object().nullable().required("Requester is required"),
  deliveryLocation: yup
    .object()
    .nullable()
    .required("Delivery Location is required"),
  requestId: yup.object().nullable().optional(),
  deliverTo: yup.array().nullable().required("Deliver To is required"),
  deliverDate: yup.object().nullable().optional(),
});
