import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  StackDivider,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInventoryItemSummary,
  ApiInventoryRequestItem,
  ApiInventoryRequestStatus,
  ApiRequest,
  CreateApiInventoryRequestItem,
  InventoryRequestSummary,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { FC, useCallback, useEffect, useState } from "react";
import { IoTrashSharp } from "react-icons/io5";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { OutlinedIconButton } from "../../../components/custom-icons/OutlinedIconButton";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addRequestItemToCart,
  cleanRequestItemsCart,
  loadInventoryItems,
} from "../../../store/inventory/inventory-item-list.slice";
import { setInventoryRequestsPage } from "../../../store/request-form/request-form.slice";
import { loadInventoryRequests } from "../../../store/request-form/thunks/inventoryRequests.thunk";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { CreateInventoryRequestForm } from "../../inventory/inventory-request/CreateInventoryRequestForm";

interface InventoryRequestsProps {
  request: ApiRequest;
  canCreateInventoryRequest?: boolean;
}

export const InventoryRequests: FC<InventoryRequestsProps> = ({
  request,
  canCreateInventoryRequest,
}) => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const { data, total, page, pageSize } = useSelector(
    (state: RootState) => state.requestForm.inventoryRequests
  );
  const [editItem, setEditItem] = useState<InventoryRequestSummary>();

  const {
    isOpen: isOpenInventoryRequest,
    onOpen: onOpenInventoryRequest,
    onClose: onCloseInventoryRequest,
  } = useDisclosure();

  const {
    isOpen: isOpenInventoryRequestEdit,
    onOpen: onOpenInventoryRequestEdit,
    onClose: onCloseInventoryRequestEdit,
  } = useDisclosure();

  const showToast = useShowToast();

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(setInventoryRequestsPage(value));
    },
    [dispatch]
  );

  const handleOnAddRequestItemEdit = useCallback(
    (item: ApiInventoryRequestItem) => {
      const itemCopy: ApiInventoryItemSummary = { ...item.item };
      "storageLocations" in itemCopy && delete itemCopy["storageLocations"];

      const newItem: CreateApiInventoryRequestItem = {
        item: itemCopy,
        quantity: item.quantity,
        unitOfMeasure: itemCopy.units,
      };

      dispatch(addRequestItemToCart(newItem));
    },
    [dispatch]
  );

  const handleCancelInventoryRequest = useCallback(
    (inventoryReqiestId: string) => {
      apiClient
        .updateInventoryRequest(currentAccount.id, inventoryReqiestId, {
          status: ApiInventoryRequestStatus.cancelled,
        })
        .then(() => {
          showToast("success", "Inventory Request was canceled successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );
          thunkDispatch(
            loadInventoryRequests({
              apiClient,
              account: currentAccount,
              requestId: request.id,
            })
          );
        })
        .catch(() => {
          showToast(
            "error",
            "something went wrong canceling an Inventory Request"
          );
        });
    },
    [apiClient, showToast, thunkDispatch, currentAccount, request.id]
  );

  const handleOnCloseModal = useCallback(() => {
    dispatch(cleanRequestItemsCart());
    onCloseInventoryRequest();
  }, [dispatch, onCloseInventoryRequest]);

  const onSubmitForm = useCallback(() => {
    if (!request) return;
    thunkDispatch(
      loadInventoryRequests({
        apiClient,
        account: currentAccount,
        requestId: request.id,
      })
    );
  }, [apiClient, currentAccount, thunkDispatch, request]);

  const handleOnEditInventoryRequest = useCallback(
    (value: InventoryRequestSummary) => {
      apiClient
        .getInventoryRequestItems(currentAccount.id, value.id)
        .then((response) => {
          response.forEach((item) => handleOnAddRequestItemEdit(item));
        });
      setEditItem(value);
      onOpenInventoryRequestEdit();
    },
    [
      onOpenInventoryRequestEdit,
      apiClient,
      currentAccount.id,
      handleOnAddRequestItemEdit,
    ]
  );

  const handleOnCloseEditModal = useCallback(() => {
    dispatch(cleanRequestItemsCart());
    onCloseInventoryRequestEdit();
  }, [onCloseInventoryRequestEdit, dispatch]);

  useEffect(() => {
    if (!request?.id) return;
    thunkDispatch(
      loadInventoryRequests({
        apiClient,
        account: currentAccount,
        requestId: request.id,
      })
    );
  }, [apiClient, currentAccount, thunkDispatch, request?.id, page]);

  return (
    <VStack gap={3} width="100%">
      <Divider />

      <HStack justifyContent="space-between" width="100%">
        <Heading size="md">Inventory Requests</Heading>
        {canCreateInventoryRequest && (
          <Button
            size={isMobile ? "sm" : "md"}
            colorScheme="blue"
            variant="outline"
            onClick={onOpenInventoryRequest}
          >
            <Icon as={MdAdd} mr={1} />
            Request Item
          </Button>
        )}
      </HStack>
      <Box width="100%">
        <VStack width="100%">
          {data.length > 0 ? (
            <>
              <VStack width="100%" gap={2} divider={<StackDivider />}>
                {!isMobile && (
                  <HStack width="100%">
                    <Heading size="sm" width="15%">
                      Request #
                    </Heading>
                    <Heading size="sm" width="20%">
                      Requester
                    </Heading>
                    <Heading size="sm" width="10%">
                      Items
                    </Heading>
                    <Heading size="sm" width="20%">
                      Deliver
                    </Heading>
                    <Heading size="sm" width="35%">
                      Status
                    </Heading>
                  </HStack>
                )}
                {data.map((inventory) =>
                  !isMobile ? (
                    <HStack
                      width="100%"
                      key={`inventoryRequests::${inventory.id}`}
                    >
                      <Text width="15%">
                        {inventory.inventoryRequestNumber}
                      </Text>
                      <Box width="20%">
                        <UserBadge value={inventory.requester} />
                      </Box>
                      <Text width="10%">{inventory.totalItems}</Text>
                      <Text width="20%">
                        {inventory.deliverDate &&
                          format(new Date(inventory.deliverDate), "MMMM d y")}
                      </Text>
                      <HStack width="35%" justifyContent="space-between">
                        <StatusBadge status={inventory.status} />
                        {canCreateInventoryRequest &&
                          inventory.status ===
                            ApiInventoryRequestStatus.pending && (
                            <HStack>
                              <OutlinedIconButton
                                icon={<Icon as={IoTrashSharp} boxSize={5} />}
                                onClick={() => {
                                  handleCancelInventoryRequest(inventory.id);
                                }}
                              />
                              <OutlinedIconButton
                                icon={<Icon as={MdEdit} boxSize={5} />}
                                onClick={() =>
                                  handleOnEditInventoryRequest(inventory)
                                }
                              />
                            </HStack>
                          )}
                      </HStack>
                    </HStack>
                  ) : (
                    <VStack
                      width="100%"
                      key={`inventoryRequests::${inventory.id}`}
                    >
                      <HStack width="100%" justifyContent="space-between">
                        <Text fontWeight="bold">
                          {inventory.inventoryRequestNumber}
                        </Text>
                        <UserBadge value={inventory.requester} />
                      </HStack>
                      <HStack width="100%" justifyContent="space-between">
                        <Text>Items: {inventory.totalItems}</Text>
                        <Text>
                          {inventory.deliverDate &&
                            format(new Date(inventory.deliverDate), "MMMM d y")}
                        </Text>
                        {canCreateInventoryRequest &&
                          inventory.status ===
                            ApiInventoryRequestStatus.pending && (
                            <HStack>
                              <OutlinedIconButton
                                icon={<Icon as={IoTrashSharp} boxSize={5} />}
                                onClick={() => {
                                  handleCancelInventoryRequest(inventory.id);
                                }}
                              />
                              <OutlinedIconButton
                                icon={<Icon as={MdEdit} boxSize={5} />}
                                onClick={() =>
                                  handleOnEditInventoryRequest(inventory)
                                }
                              />
                            </HStack>
                          )}
                      </HStack>
                    </VStack>
                  )
                )}
                {editItem && isOpenInventoryRequestEdit && (
                  <AccountModal
                    title={
                      <VStack alignItems="flex-start" fontWeight="bold" gap={2}>
                        <Heading fontSize="2xl">Request Items</Heading>
                        <Text fontSize="md">
                          This order will be processed after administrator
                          approval.
                        </Text>
                      </VStack>
                    }
                    isOpen={isOpenInventoryRequestEdit}
                    onClose={handleOnCloseEditModal}
                    content={
                      <CreateInventoryRequestForm
                        onCancel={handleOnCloseEditModal}
                        request={request}
                        onSubmit={onSubmitForm}
                        inventoryRequest={editItem}
                      />
                    }
                  />
                )}
              </VStack>
              <Box width="100%">
                <Pager
                  currentPage={page || 1}
                  pageSize={pageSize || 20}
                  total={total}
                  onPageChange={handleOnPageChange}
                />
              </Box>
            </>
          ) : (
            <Text width="100%">Add an item used for this request.</Text>
          )}
        </VStack>
      </Box>
      {isOpenInventoryRequest && (
        <AccountModal
          title={
            <VStack alignItems="flex-start" fontWeight="bold" gap={2}>
              <Heading fontSize="2xl">Request Items</Heading>
              <Text fontSize="md">
                This order will be processed after administrator approval.
              </Text>
            </VStack>
          }
          isOpen={isOpenInventoryRequest}
          onClose={handleOnCloseModal}
          content={
            <CreateInventoryRequestForm
              onCancel={handleOnCloseModal}
              request={request}
              onSubmit={onSubmitForm}
            />
          }
        />
      )}
    </VStack>
  );
};
