import { Box, Flex, VStack } from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestStatus,
  ApiWorkflow,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";
import { generateTransition } from "../../../store/request-form/request-form.helpers";
import { StatusChangeButtons } from "../../request-form/StatusChangeButtons";
import { RequestRowIcons } from "./RequestRowIcons";
import { RequestSchedulingSummary } from "./RequestSchedulingSummary";

export const ActionsColumn = ({
  request,
  showIcons,
  onStatusChange,
  CustomActionElement,
}: {
  showIcons?: boolean;
  request: ApiRequest;
  CustomActionElement?: React.ReactElement | null;
  onStatusChange?: (params: {
    request: ApiRequest;
    status: ApiRequestStatus;
    workflow: ApiWorkflow;
    policy: WorkflowPolicy;
  }) => void;
}) => {
  const { isProductAdmin } = useAuthentication();
  const { policyMap, workflows, categoriesMap, descendantsMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const { transitionMap } = useSelector(
    (state: RootState) => state.requestList
  );

  const hasIcons = useMemo(
    () =>
      request.totalAttachments > 0 ||
      request.totalAssets > 0 ||
      request.totalTaskbooks > 0 ||
      request.followedBy.length > 0 ||
      request.hasReminder,
    [request]
  );

  const policy = useMemo(
    () => policyMap[request.workflow.id],
    [request, policyMap]
  );

  const workflow = useMemo(
    () => workflows.find((wf) => wf.id === request.workflow.id)!,
    [request, workflows]
  );

  const transition = useMemo(() => {
    if (transitionMap[request.id]) {
      return transitionMap[request.id].transition;
    }
    return generateTransition(
      workflow,
      policy,
      request,
      isProductAdmin,
      descendantsMap,
      categoriesMap
    );
  }, [
    workflow,
    policy,
    request,
    isProductAdmin,
    descendantsMap,
    categoriesMap,
    transitionMap,
  ]);

  const handleStatusChange = useCallback(
    (status: ApiRequestStatus) => {
      onStatusChange && onStatusChange({ request, status, workflow, policy });
    },
    [onStatusChange, request, workflow, policy]
  );

  return (
    <VStack spacing={3.5} justifyContent="space-between">
      <Flex alignSelf={{ base: "center", md: "end", lg: "end" }}>
        <RequestSchedulingSummary request={request} />
      </Flex>
      {showIcons && hasIcons && <RequestRowIcons request={request} />}
      {showIcons && !hasIcons && <Box>&nbsp;</Box>}
      {transition && request && onStatusChange ? (
        <Flex flex={1}>
          <StatusChangeButtons
            request={request}
            onStatusChange={handleStatusChange}
            transition={transition}
            showHoldButton={false}
            canReopen={false}
          />
        </Flex>
      ) : null}

      {CustomActionElement && CustomActionElement}
    </VStack>
  );
};
