import { Box, Spinner } from "@chakra-ui/react";
import {
  ApiAccount,
  ApiClient,
  ApiEventStatus,
  ApiToken,
  ApiUser,
  ApiUserAccountLink,
} from "@operations-hero/lib-api-client";
import axios, { AxiosError } from "axios";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useThunkDispatch } from "../../store";
import {
  initAuth,
  setAuthType,
  setCurrentAccount,
} from "../../store/auth.slice";
import { setErrorLoading } from "../../store/local-cache.slice";
import { setIsOneClickRequestAction } from "../../store/request-form/request-form.slice";

const UNAUTHORIZED_ERROR_CODE = 401;
export interface AuthenticationContextInterface {
  token: ApiToken;
  apiClient: ApiClient;
  currentUser: ApiUser;
  accounts: ApiUserAccountLink[];
  currentAccount: ApiAccount;
  setCurrentAccount: (acct: ApiUserAccountLink) => void;
  isProductStandard: boolean;
  isProductAdmin: boolean;
  isEventAdmin: boolean;
  isEventStandard: boolean;
  isInventoryStandard: boolean;
  isInventoryAdmin: boolean;
  isEnergyUser: boolean;
  isPlanningAdmin: boolean;
  isInternal: boolean;
}

// This is a hack because other components will not see these values as null because they are not mounted until the values are set
const AuthenticationContext = createContext<AuthenticationContextInterface>(
  {} as any as AuthenticationContextInterface
);

export const useAuthentication = () => useContext(AuthenticationContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<ApiToken>({} as any as ApiToken);
  const [apiClient, setApiClient] = useState<ApiClient>({} as any as ApiClient);
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const {
    currentUser,
    accounts,
    currentAccount,
    isProductStandard,
    isProductAdmin,
    isEventAdmin,
    isEventStandard,
    isInventoryStandard,
    isInventoryAdmin,
    isEnergyUser,
    isPlanningAdmin,
    isInternal,
    authType,
  } = useSelector((state: RootState) => state.auth);

  axios.interceptors.response.use(
    (response) => response,
    (err: AxiosError) => {
      if (
        err.isAxiosError &&
        err.response?.status === UNAUTHORIZED_ERROR_CODE
      ) {
        window.location.href = `${process.env.REACT_APP_AUTH_SERVER}/logout`;
        return;
      }
      return Promise.reject(err);
    }
  );

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const setCurrentAccountWrapper = useCallback(
    (acct: ApiUserAccountLink) => {
      dispatch(setCurrentAccount(acct));
    },
    [dispatch]
  );

  const getReturnPath = useCallback(
    (apiClient: ApiClient) => {
      const returnPath = localStorage.getItem("returnUrl");

      if (!returnPath) return "/";

      if (returnPath.includes("one-click")) {
        const queryStringParams = returnPath.slice(returnPath.indexOf("?"));
        const searchParams = new URLSearchParams(queryStringParams);
        const metadata = searchParams.get("meta");

        if (!metadata) return "/";

        const meta = JSON.parse(window.atob(metadata));
        if ("requestKey" in meta && "action" in meta) {
          dispatch(setIsOneClickRequestAction(true));
          return `/requests/${meta.requestKey}?meta=${metadata}`;
        }

        if ("eventId" in meta && "action" in meta && "accountId" in meta) {
          const { accountId, eventId, action } = meta;
          const eventStatus =
            action === "approve"
              ? ApiEventStatus.confirmed
              : ApiEventStatus.declined;

          const updateEventStatus = async () => {
            await apiClient.updateEvent(accountId, eventId, {
              status: eventStatus,
            });
          };

          updateEventStatus();
          return `/events/${eventId}`;
        }
      }

      return returnPath;
    },
    [dispatch]
  );

  const handleInitAuth = useCallback(
    (accessToken: string, accountId: string | null) => {
      const token = { accessToken };

      // Create initial context
      const apiClient = new ApiClient({
        apiServer: process.env.REACT_APP_API_SERVER || "",
      });

      apiClient.setToken(token);
      const returnPath = getReturnPath(apiClient);

      thunkDispatch(initAuth({ apiClient, returnPath, accountId }))
        .then(() => {
          setToken(token);
          setApiClient(apiClient);
          // TODO: multi account without access to this product
          //       - make sure default is good
          //       - no access warning
          //       - expired account products?

          setLoading(false);
          localStorage.removeItem("returnUrl");
          navigate(returnPath || "/", { replace: true });
        })
        .then(
          () => {},
          (err) => {
            console.log("loading error auth", err);
            dispatch(setErrorLoading(true));
          }
        );
    },
    [getReturnPath, navigate, thunkDispatch, dispatch]
  );

  const exchangeMagicToken = useCallback(
    (uuid: string, metadata: string) => {
      const apiAuth = new ApiClient({
        apiServer: process.env.REACT_APP_AUTH_SERVER || "",
      });

      const meta = JSON.parse(window.atob(metadata));
      const { accountId } = meta;

      apiAuth
        .exchangeMagicToken(uuid)
        .then((response) => {
          handleInitAuth(response.access_token, accountId);
          dispatch(setAuthType("magicToken"));
        })
        .catch((error) => {
          window.location.href = `${process.env.REACT_APP_AUTH_SERVER}/login`;
          return;
        });
    },
    [dispatch, handleInitAuth]
  );

  useEffect(() => {
    if (authType !== "idle") return;
    const qs = location.search;
    const urlSearchParams = new URLSearchParams(qs);
    const accessToken = urlSearchParams.get("access_token");
    const accountId = urlSearchParams.get("accountId");
    const magicToken = urlSearchParams.get("magic-token");
    const metadata = urlSearchParams.get("meta");

    if (!accessToken) {
      const returnUrl = location.pathname + location.search;
      localStorage.setItem("returnUrl", returnUrl);

      if (!magicToken || !metadata) {
        if (accountId)
          window.location.href = `${process.env.REACT_APP_AUTH_SERVER}/login?accountId=${accountId}`;
        else
          window.location.href = `${process.env.REACT_APP_AUTH_SERVER}/login`;
        return;
      }
      exchangeMagicToken(magicToken, metadata);

      return;
    }

    handleInitAuth(accessToken, accountId);
    dispatch(setAuthType("common"));
  }, [
    dispatch,
    exchangeMagicToken,
    handleInitAuth,
    authType,
    location.pathname,
    location.search,
  ]);

  return loading ? (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      css={{ height: "100%", width: "100%" }}
    >
      <Spinner style={{ marginTop: "50%" }} />
    </Box>
  ) : (
    <AuthenticationContext.Provider
      value={{
        token,
        currentUser,
        apiClient,
        accounts,
        currentAccount,
        isProductStandard,
        isProductAdmin,
        isEventAdmin,
        isEventStandard,
        isInventoryStandard,
        isInventoryAdmin,
        isEnergyUser,
        isPlanningAdmin,
        isInternal,
        setCurrentAccount: setCurrentAccountWrapper,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
