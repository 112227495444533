import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { EventInput } from "@fullcalendar/react";
import {
  ApiDay,
  ApiDays,
  ApiEventOccurrence,
  ApiSpaceReference,
  ApiVenueSummary,
  CreateApiEventOccurrence,
} from "@operations-hero/lib-api-client";
import { format, isBefore, parse } from "date-fns";
import { useFormikContext } from "formik";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { CalendarDatePicker } from "../../../../components/calendar-datepicker/CalendarDatePicker";
import { getCronOcurrences } from "../../../../components/cron/cron-helpers/CronOccurrences";
import {
  CronValues,
  CustomCronGenerator,
} from "../../../../components/cron/CustomCron";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  LocaleDate,
} from "../../../../components/dates/LocaleDate";
import { StyledDatePicker } from "../../../../components/inputs/StyledDatePicker";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState } from "../../../../store";
import { addTwoHours } from "../../../../utils/addTwoHoursToDate";
import { isObjectEmpty } from "../../../../utils/compareObjects";
import { filterAllowedDays } from "../../../../utils/filterAllowedDays";
import { filterAllowedTime } from "../../../../utils/filterAllowedTime";
import { getNearestVenueDate } from "../../../../utils/getNearestVenueDate";
import { getVenueTimes } from "../../../../utils/getVenueTimes";
import { EventFormValues } from "../EventForm";
import { LimitVenueInfo } from "./LimitVenueInfo";

export interface EventOccurrencesProps {
  start: string;
  end: string;
}

interface ScheduleEventProps {
  selectedVenue: ApiVenueSummary | null;
  multipleOccurrencesText?: string;
  occurrences: CreateApiEventOccurrence[];
  selectedSpaces: ApiSpaceReference[];
  setOccurrences: Dispatch<SetStateAction<CreateApiEventOccurrence[]>>;
  setStyleDatePickerStateIsShowingSelectADate?: (bool: boolean) => void;
  checkConflicts: (occurrences: CreateApiEventOccurrence[]) => void;
  firstOccurrence?: ApiEventOccurrence;
}

const WeekDayNumbers: { [key in ApiDays]: number } = {
  [ApiDays.sun]: 0,
  [ApiDays.mon]: 1,
  [ApiDays.tue]: 2,
  [ApiDays.wed]: 3,
  [ApiDays.thu]: 4,
  [ApiDays.fri]: 5,
  [ApiDays.sat]: 6,
};

export const ScheduleEvent: FC<ScheduleEventProps> = ({
  occurrences,
  selectedVenue,
  selectedSpaces,
  setOccurrences,
  setStyleDatePickerStateIsShowingSelectADate,
  checkConflicts,
  multipleOccurrencesText,
  firstOccurrence,
}) => {
  const { submitCount } = useFormikContext<EventFormValues>();
  const errorColor = useColorModeValue("red.500", "red.300");
  const DATE_FORMAT = "eeee, MMM-dd-yyyy";
  const [blockedVenueOrSpace, setBlockedVenueOrSpace] =
    useState<Record<string, string[]>>();
  const [willRepeat, setWillRepeat] = useState(false);
  const [cron, setCron] = useState<CronValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);
  const [isEndTimeSelected, setIsEndTimeSelected] = useState(false);
  const [isRecurringDateSelected, setIsRecurringDateSelected] = useState(false);
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    firstOccurrence ? new Date(firstOccurrence.start) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    firstOccurrence ? new Date(firstOccurrence.end) : null
  );
  const [venueStartHour, setVenueStartHour] = useState<Date | null>(
    firstOccurrence ? new Date(firstOccurrence.start) : null
  );
  const [venueEndHour, setVenueEndHour] = useState<Date | null>(
    firstOccurrence ? new Date(firstOccurrence.end) : null
  );
  const [enabledDay, setEnabledDay] = useState<string | undefined>();
  const [calendarDates, setCalendarDates] = useState<EventInput[]>([]);
  const [allowedDays, setAllowedDays] = useState<number[]>([]);
  const showToast = useShowToast();
  const { apiClient, currentAccount } = useAuthentication();
  const prevSeletedVenue = usePrevious(selectedVenue);

  const { limitAllEventsByVenueHours } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );
  const handleOnCheckConflicts = useCallback(
    (occurrences: CreateApiEventOccurrence[]) => {
      if (
        selectedVenue &&
        selectedVenue.id &&
        selectedSpaces.length > 0 &&
        occurrences.length > 0
      ) {
        checkConflicts(occurrences);
      }
    },
    [checkConflicts, selectedSpaces.length, selectedVenue]
  );

  const handleOnChangeWillRepeat = useCallback(() => {
    if (willRepeat) {
      setCron(undefined);
      setOccurrences([]);
      setCalendarDates([]);
    }

    setWillRepeat(!willRepeat);
    handleOnCheckConflicts(occurrences);
  }, [handleOnCheckConflicts, occurrences, setOccurrences, willRepeat]);

  const getOccurrences = useCallback(() => {
    if (!cron || !startDate || !endDate) return;
    const allOccurrences = getCronOcurrences(
      cron,
      startDate,
      endDate,
      limitAllEventsByVenueHours,
      limitAllEventsByVenueHours ? allowedDays : undefined
    );
    const newOccurrences = allOccurrences.map(
      (occurrence) => ({
        ...occurrence,
        venue: selectedVenue ? selectedVenue.id : "",
        spaces: selectedSpaces || [],
      }),
      []
    );
    handleOnCheckConflicts(newOccurrences);
    setOccurrences(newOccurrences);
  }, [
    cron,
    startDate,
    endDate,
    limitAllEventsByVenueHours,
    allowedDays,
    handleOnCheckConflicts,
    setOccurrences,
    selectedVenue,
    selectedSpaces,
  ]);

  const filterDay = useCallback(
    (date: Date) => {
      return filterAllowedDays({ date, venue: selectedVenue });
    },
    [selectedVenue]
  );

  const filterTime = useCallback(
    (time: Date) => {
      return filterAllowedTime(time, venueStartHour, venueEndHour);
    },
    [venueStartHour, venueEndHour]
  );

  const getVenueHours = useCallback(
    (selectedDate: Date | null) => {
      return getVenueTimes(selectedDate, selectedVenue);
    },
    [selectedVenue]
  );

  const handleOnChangeStartDate = useCallback(
    (newDate: Date | null) => {
      const venueHours = getVenueHours(newDate);
      let endDateCopy = endDate ? new Date(endDate) : new Date();
      if (newDate && endDate && newDate.getTime() > endDate.getTime()) {
        const lastDayOfMonthStart = new Date(
          newDate.getFullYear(),
          newDate.getMonth() + 1,
          0
        ).getDate();
        endDateCopy = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          lastDayOfMonthStart,
          endDate.getHours(),
          endDate.getMinutes()
        );

        setEndDate(endDateCopy);

        setVenueEndHour(
          new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            lastDayOfMonthStart,
            endDate.getHours(),
            endDate.getMinutes()
          )
        );
      }
      if (!venueHours || !newDate) {
        setStartDate(newDate);
        limitAllEventsByVenueHours && setVenueStartHour(newDate);
        return;
      }
      const {
        venueStartHour,
        venueStartMinutes,
        venueEndHour,
        venueEndMinutes,
      } = venueHours;

      if (Number.isNaN(venueStartHour) || Number.isNaN(venueStartMinutes)) {
        setStartDate(newDate);
        limitAllEventsByVenueHours && setVenueStartHour(newDate);
        return;
      }

      const newDateCopy = new Date(newDate);
      newDateCopy.setHours(venueStartHour);
      newDateCopy.setMinutes(venueStartMinutes);
      setStartDate(newDateCopy);
      limitAllEventsByVenueHours && setVenueStartHour(newDateCopy);

      if (endDate) {
        if (Number.isNaN(venueEndHour)) {
          const { newHours, newMinutes } = addTwoHours(
            venueStartHour,
            venueStartMinutes
          );
          endDateCopy.setHours(newHours);
          endDateCopy.setMinutes(newMinutes);
          setEndDate(endDateCopy);
          limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
          return;
        }

        endDateCopy.setHours(venueEndHour);
        endDateCopy.setMinutes(venueEndMinutes);
        setEndDate(endDateCopy);
        limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
      }
    },
    [endDate, getVenueHours, limitAllEventsByVenueHours]
  );

  const onSelectedVenueChange = useCallback(() => {
    const venueHours = getVenueHours(startDate);
    if (!venueHours || !startDate) {
      return;
    }
    const { venueStartHour, venueStartMinutes, venueEndHour, venueEndMinutes } =
      venueHours;

    if (Number.isNaN(venueStartHour) || Number.isNaN(venueStartMinutes)) {
      return;
    }

    const newDateCopy = new Date(startDate);
    newDateCopy.setHours(venueStartHour);
    newDateCopy.setMinutes(venueStartMinutes);
    setStartDate(newDateCopy);
    limitAllEventsByVenueHours && setVenueStartHour(newDateCopy);

    if (endDate) {
      if (Number.isNaN(venueEndHour)) {
        const endDateCopy = new Date(endDate);
        const { newHours, newMinutes } = addTwoHours(
          venueStartHour,
          venueStartMinutes
        );
        endDateCopy.setHours(newHours);
        endDateCopy.setMinutes(newMinutes);
        setEndDate(endDateCopy);
        limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
        return;
      }

      const endDateCopy = new Date(endDate);
      endDateCopy.setHours(venueEndHour);
      endDateCopy.setMinutes(venueEndMinutes);
      setEndDate(endDateCopy);
      limitAllEventsByVenueHours && setVenueEndHour(endDateCopy);
    }
  }, [endDate, getVenueHours, startDate, limitAllEventsByVenueHours]);

  const getCronExpression = useCallback(
    ({ cronExpression, frequency, executeEvery, weekOfMonth }: CronValues) => {
      setCron({ cronExpression, frequency, executeEvery, weekOfMonth });
    },
    []
  );

  const handleOnChangeHours = useCallback(
    (value: Date | null, type: "start" | "end") => {
      if (!value) return;

      const hours = value.getHours();
      const minutes = value.getMinutes();

      if (type === "start") {
        const startDateCopy = new Date(startDate || value);
        startDateCopy.setHours(hours);
        startDateCopy.setMinutes(minutes);
        setStartDate(startDateCopy);

        const endDateCopy = new Date(endDate || value);
        const { newHours, newMinutes } = addTwoHours(hours, minutes);
        endDateCopy.setHours(newHours);
        endDateCopy.setMinutes(newMinutes);
        if (
          limitAllEventsByVenueHours &&
          venueEndHour &&
          endDateCopy.getTime() > venueEndHour.getTime()
        ) {
          setEndDate(venueEndHour);
          return;
        }
        setEndDate(endDateCopy);
        return;
      }

      if (type === "end") {
        if (!endDate) return;
        const endDateCopy = new Date(endDate);
        endDateCopy.setHours(hours);
        endDateCopy.setMinutes(minutes);
        setEndDate(endDateCopy);
      }
    },
    [endDate, startDate, venueEndHour, limitAllEventsByVenueHours]
  );

  const setInitialDate = useCallback(() => {
    if (!selectedVenue) {
      setStartDate(null);
      return;
    }
    const date = getNearestVenueDate(selectedVenue);
    setStartDate(date);
    handleOnChangeStartDate(date);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenue]);

  const handleSetEnabledDay = useCallback(() => {
    if (!startDate || !selectedVenue) return;
    setEnabledDay(startDate.getDay().toString());
  }, [startDate, selectedVenue]);

  const getBlockedVenueSpaces = useCallback(() => {
    apiClient
      .findVenuesOrSpacesBlocked(currentAccount.id, { pageSize: 100 })
      .then((response) => {
        const data: Record<string, string[]> = {};
        response.data.forEach((item) => {
          if ("space" in item) {
            data[item.space.id] = item.datesToBlock.map((d) => d.start);
          }
          if ("venue" in item) {
            data[item.venue.id] = item.datesToBlock.map((d) => d.start);
          }
        });
        setBlockedVenueOrSpace(data);
      })
      .catch(() =>
        showToast(
          "error",
          "Something went wrong loading blocked venues or spaces"
        )
      );
  }, [apiClient, currentAccount.id, showToast]);

  const blockedDates = useMemo(() => {
    if (!blockedVenueOrSpace || isObjectEmpty(blockedVenueOrSpace)) return [];

    let result: string[] = [];

    if (selectedVenue) {
      const blockedVenue = blockedVenueOrSpace[selectedVenue.id];
      blockedVenue && blockedVenue.forEach((e) => result.push(e));
    }

    if (selectedSpaces.length > 0) {
      selectedSpaces.forEach((bs) => {
        const blockedSpaces =
          blockedVenueOrSpace[typeof bs === "string" ? bs : bs.id];
        blockedSpaces && blockedSpaces.forEach((e) => result.push(e));
      });
    }

    return result;
  }, [blockedVenueOrSpace, selectedSpaces, selectedVenue]);

  const setSelectedDays = useCallback(() => {
    let daysRelation = [0, 0, 0, 0, 0, 0, 0];
    calendarDates
      .filter((dates) => !dates.isBlocked)
      .forEach((date) => {
        if (date.start)
          daysRelation[new Date(date.start.toString()).getDay()] =
            daysRelation[new Date(date.start.toString()).getDay()] + 1;
      });
    return daysRelation;
  }, [calendarDates]);

  useEffect(() => {
    !willRepeat && setSelectedDays();
  }, [willRepeat, setSelectedDays]);

  useEffect(() => {
    if (prevSeletedVenue !== selectedVenue) {
      onSelectedVenueChange();
    }
  }, [onSelectedVenueChange, prevSeletedVenue, selectedVenue]);

  useEffect(() => {
    if (!cron || cron.cronExpression === "") {
      return;
    }
    setIsLoading(true);
    getOccurrences();
    setIsLoading(false);
  }, [cron, endDate, getOccurrences, setOccurrences, startDate, willRepeat]);

  useEffect(() => {
    if (limitAllEventsByVenueHours) {
      setInitialDate();
    }
  }, [limitAllEventsByVenueHours, setInitialDate]);

  useEffect(() => {
    if (limitAllEventsByVenueHours && willRepeat) {
      handleSetEnabledDay();
    }
  }, [handleSetEnabledDay, limitAllEventsByVenueHours, willRepeat]);

  useEffect(() => {
    if (willRepeat) return;
    const newEventOccurrences = calendarDates
      .filter((date) => !date.isBlocked)
      .map((date) => {
        const { start, end } = date;
        const newStart = new Date(start ? start.toString() : "");
        const newEnd = new Date(end ? end.toString() : "");

        startDate &&
          newStart.setHours(startDate.getHours(), startDate.getMinutes());
        endDate && newEnd.setHours(endDate.getHours(), endDate.getMinutes());

        return {
          start: newStart.toISOString(),
          end: newEnd.toISOString(),
          spaces: selectedSpaces,
          venue: selectedVenue ? selectedVenue.id : "",
        };
      });

    setOccurrences(newEventOccurrences);
    handleOnCheckConflicts(newEventOccurrences);
  }, [
    calendarDates,
    endDate,
    handleOnCheckConflicts,
    selectedSpaces,
    selectedVenue,
    setOccurrences,
    startDate,
    willRepeat,
  ]);

  useEffect(() => {
    getBlockedVenueSpaces();
  }, [getBlockedVenueSpaces, selectedSpaces, selectedVenue]);

  useEffect(() => {
    if (!limitAllEventsByVenueHours || !willRepeat || !selectedVenue) return;
    const daysAllowed: number[] = [];

    Object.values(ApiDays).forEach((valueDay) => {
      const day = valueDay.toLocaleLowerCase() as ApiDay;
      if (
        startDate &&
        endDate &&
        selectedVenue?.hours[day].start &&
        selectedVenue?.hours[day].end
      ) {
        const startFormatted = format(startDate, "h:mm a");
        const endFormatted = format(endDate, "h:mm a");

        const startParsed = parse(startFormatted, "h:mm a", new Date());
        const selectedStartParsed = parse(
          selectedVenue.hours[day].start,
          "h:mm a",
          new Date()
        );
        const isBeforeStartDate = isBefore(startParsed, selectedStartParsed);

        const endParsed = parse(endFormatted, "h:mm a", new Date());
        const selectedEndParsed = parse(
          selectedVenue.hours[day].end,
          "h:mm a",
          new Date()
        );
        const isBeforeEndDate = isBefore(selectedEndParsed, endParsed);

        if (!isBeforeStartDate && !isBeforeEndDate) {
          daysAllowed.push(WeekDayNumbers[valueDay]);
        }
      }
    });

    setAllowedDays(daysAllowed);
  }, [
    endDate,
    limitAllEventsByVenueHours,
    selectedVenue,
    selectedVenue?.hours,
    selectedVenue?.hours.friday.end,
    selectedVenue?.hours.friday.start,
    selectedVenue?.hours.monday.end,
    selectedVenue?.hours.monday.start,
    selectedVenue?.hours.saturday.end,
    selectedVenue?.hours.saturday.start,
    selectedVenue?.hours.sunday.end,
    selectedVenue?.hours.sunday.start,
    selectedVenue?.hours.thursday.end,
    selectedVenue?.hours.thursday.start,
    selectedVenue?.hours.tuesday.end,
    selectedVenue?.hours.tuesday.start,
    selectedVenue?.hours.wednesday.end,
    selectedVenue?.hours.wednesday.start,
    startDate,
    willRepeat,
  ]);

  useEffect(() => {
    if (!setStyleDatePickerStateIsShowingSelectADate) return;

    if (
      isTimeSelected &&
      (calendarDates.length !== 0 || isRecurringDateSelected)
    ) {
      setStyleDatePickerStateIsShowingSelectADate(true);
    } else {
      setStyleDatePickerStateIsShowingSelectADate(false);
    }
  }, [
    startDate,
    endDate,
    setStyleDatePickerStateIsShowingSelectADate,
    isTimeSelected,
    isRecurringDateSelected,
    calendarDates,
  ]);
  return (
    <Grid gap={4} templateColumns="repeat(12, 1fr)">
      <GridItem colSpan={12}>
        <Flex justifyContent="space-between">
          <Checkbox
            isChecked={willRepeat}
            onChange={handleOnChangeWillRepeat}
            w="max-content"
          >
            <Text fontWeight={500}>
              {multipleOccurrencesText || "Use recurring pattern"}
            </Text>
          </Checkbox>
        </Flex>
      </GridItem>

      {willRepeat && (
        <GridItem colSpan={[12, 6, 5]}>
          <FormControl>
            <FormLabel>Begin schedule on</FormLabel>
            <StyledDatePicker
              value={startDate}
              name="beginDate"
              format={DATE_FORMAT}
              onChange={(date) => {
                handleOnChangeStartDate(date);
                setIsRecurringDateSelected(date !== null);
              }}
              excludeDates={blockedDates.map(
                (stringDate) => new Date(stringDate)
              )}
              filterDate={limitAllEventsByVenueHours ? filterDay : undefined}
            />
          </FormControl>
        </GridItem>
      )}

      {willRepeat && (
        <GridItem colSpan={[12, 6, 5]}>
          <FormControl>
            <FormLabel>End schedule on</FormLabel>
            <StyledDatePicker
              value={endDate}
              name="endDate"
              format={DATE_FORMAT}
              excludeDates={blockedDates.map(
                (stringDate) => new Date(stringDate)
              )}
              onChange={(date) => {
                setEndDate(date);
              }}
            />
          </FormControl>
        </GridItem>
      )}

      {!willRepeat && (
        <GridItem colSpan={12}>
          <CalendarDatePicker
            values={calendarDates}
            setValues={setCalendarDates}
            blockedDates={blockedDates}
          />
          {submitCount > 0 && calendarDates.length === 0 && (
            <>
              <Text mt={2} color={errorColor} fontSize="sm">
                You must select at least 1 date
              </Text>
            </>
          )}
        </GridItem>
      )}

      {submitCount > 0 && !isRecurringDateSelected && willRepeat && (
        <GridItem colSpan={12}>
          <Text mt={2} color={errorColor} fontSize="sm">
            You must select at least 1 date
          </Text>
        </GridItem>
      )}

      <GridItem colSpan={12}>
        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={[6, 6, 3]} mr={4}>
            <FormLabel>Events starts at</FormLabel>
            <StyledDatePicker
              value={isStartTimeSelected ? startDate : null}
              placeholder="Select a time"
              showTime
              showTimeOnly
              name="startHour"
              format="h:mm aa"
              className="hourPicker"
              onChange={(value) => {
                setIsStartTimeSelected(value !== null);
                setIsEndTimeSelected(value !== null);
                handleOnChangeHours(value, "start");
                setIsTimeSelected(value !== null);
              }}
              filterTime={
                limitAllEventsByVenueHours && selectedVenue
                  ? filterTime
                  : undefined
              }
            />
          </GridItem>

          <GridItem colSpan={[6, 6, 3]}>
            <FormLabel>Events ends at</FormLabel>
            <StyledDatePicker
              value={isEndTimeSelected ? endDate : null}
              placeholder="Select a time"
              showTime
              showTimeOnly
              name="endHour"
              format="h:mm aa"
              className="hourPicker"
              onChange={(value) => {
                setIsEndTimeSelected(value !== null);
                handleOnChangeHours(value, "end");
              }}
              filterTime={
                limitAllEventsByVenueHours && selectedVenue
                  ? filterTime
                  : undefined
              }
            />
          </GridItem>
        </Grid>
        {!isTimeSelected && submitCount > 0 && (
          <>
            <Text mt={2} color={errorColor} fontSize="sm">
              You must select a time
            </Text>
          </>
        )}
      </GridItem>

      {selectedVenue && limitAllEventsByVenueHours && (
        <GridItem colSpan={12}>
          <LimitVenueInfo />
        </GridItem>
      )}
      {willRepeat && (
        <>
          {startDate && (
            <GridItem colSpan={12}>
              <CustomCronGenerator
                InitialCustomCronValues={{
                  initialCronExpression: "",
                  initialExecuteEvery: 1,
                  initialFrecuency: "monthly",
                  weekOfMonth: null,
                }}
                selectedStartDate={startDate || new Date()}
                withWeeklyOptions={true}
                getCronExpression={getCronExpression}
                disabledDays={enabledDay}
                allowedDays={
                  limitAllEventsByVenueHours ? allowedDays : undefined
                }
              />
            </GridItem>
          )}
        </>
      )}
      <GridItem colSpan={12}>
        <Alert
          status="info"
          borderRadius={6}
          color="blue.600"
          bgColor="blue.50"
        >
          <AlertIcon />
          <Box>
            {isLoading ? (
              <Spinner color="blue.500" />
            ) : (
              <>
                <AlertTitle>
                  {occurrences.length} event date(s) added.
                </AlertTitle>
                <AlertDescription>
                  {occurrences.slice(0, 3).map((occ, idx) => (
                    <LocaleDate
                      key={occ.start}
                      date={occ.start}
                      options={{
                        ...DEFAULT_LOCALE_DATE_OPTS,
                        weekday: undefined,
                      }}
                      textProps={{
                        _after: {
                          content:
                            idx + 1 < occurrences.length && idx < 2
                              ? '", "'
                              : '""',
                        },
                      }}
                    />
                  ))}
                  {occurrences.length > 3 &&
                    ` and ${occurrences.length - 3} more`}
                </AlertDescription>
              </>
            )}
          </Box>
        </Alert>
      </GridItem>
    </Grid>
  );
};

export default React.memo(ScheduleEvent);
