import { useColorModeValue } from "@chakra-ui/react";
import { ApiItemAdjustmentReason } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { SingleValue } from "react-select";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";

interface InventoryReasonSelectProps {
  value: ApiItemAdjustmentReason | null;
  onChange: (value: ApiItemAdjustmentReason) => void;
  isInvalid?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

export const InventoryAuditReasonSelect = ({
  value,
  onChange,
  isInvalid,
  placeholder,
  isDisabled,
}: InventoryReasonSelectProps) => {
  const classTheme = useColorModeValue("light-theme", "dark-theme");

  const options = Object.values(ApiItemAdjustmentReason).map((item) => ({
    value: item,
    label: capitalizeFirstLetter(item.replace(/([A-Z])/g, " $1").trim()),
  }));

  const handleChange = useCallback(
    (
      newValue: SingleValue<{ value: ApiItemAdjustmentReason; label: string }>,
    ) => {
      if (newValue) {
        onChange(newValue.value);
      }
    },
    [onChange],
  );

  const components = useMemo(getCustomSelectComponents, []);

  return (
    <Select
      className={classTheme}
      isDisabled={isDisabled}
      closeMenuOnSelect={true}
      placeholder={placeholder}
      isInvalid={isInvalid}
      options={options}
      onChange={handleChange}
      components={components}
      chakraStyles={commonStyles}
      value={value ? options.find((x) => x.value === value) : null}
    />
  );
};
