import { useBreakpointValue } from "@chakra-ui/react";

export const useScreenBreakpoints = () => {
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
  });

  const isTablet = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: false,
  });

  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });

  return {
    isDesktop: Boolean(isDesktop),
    isTablet: Boolean(isTablet),
    isMobile: Boolean(isMobile),
  };
};
