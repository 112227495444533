import {
  ApiClient,
  CreateApiRequestReminder,
  UpdateApiRequestReminder,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface LoadRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
}

export const loadRequestReminder = createAsyncThunk(
  "requests/reminder",
  async (params: LoadRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId } = params;

    const result = await apiClient.getRequestReminder(accountId, requestId);
    return result;
  }
);

export const loadRequestReminderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadRequestReminder.fulfilled, (state, action) => {
    if (state.request) {
      state.reminder.isInit = true;
      if (action.payload) {
        state.reminder.data = action.payload;
      }
    }
  });
};

export interface CreateRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  reminder: CreateApiRequestReminder;
}

export const createRequestReminder = createAsyncThunk(
  "requests/reminder/create",
  async (params: CreateRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId, reminder } = params;

    const result = await apiClient.createRequestReminder(
      accountId,
      requestId,
      reminder
    );
    return result;
  }
);

export const createRequestReminderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createRequestReminder.fulfilled, (state, action) => {
    if (state.request) {
      state.reminder.data = action.payload;
    }
  });
};

export interface UpdateRequestReminderParams {
  apiClient: ApiClient;
  accountId: string;
  requestId: string;
  reminder: UpdateApiRequestReminder;
}

export const updateRequestReminder = createAsyncThunk(
  "requests/reminder/update",
  async (params: UpdateRequestReminderParams, thunkAPI) => {
    const { apiClient, accountId, requestId, reminder } = params;

    return await apiClient.updateRequestReminder(
      accountId,
      requestId,
      reminder
    );
  }
);

export const updateRequestReminderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateRequestReminder.fulfilled, (state, action) => {
    if (state.request) {
      state.reminder.data = action.payload;
    }
  });
};
