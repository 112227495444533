import { ApiClient } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { getTimeZoneIanaFormat } from "../../../utils/timezone";
import {
  initialStateValues,
  ScheduledRequestProps,
  ScheduleProps,
  ScheduleRequestSliceState,
  SheculedRequestAssetsProps,
  SheculedRequestTaskbooksProps,
} from "../schedule-request-form.slice";
import { getNextMonthFirstDay } from "../scheduled-request-helpers";

interface GetScheduledRequestFormProps {
  apiClient: ApiClient;
  accountId: string;
  scheduledRequestId: string;
}

export const getScheduledRequestFormValues = createAsyncThunk(
  "account/getScheduleRequestValues",
  async (params: GetScheduledRequestFormProps, thunkAPI) => {
    const { apiClient, accountId, scheduledRequestId } = params;

    let page = 1;
    const pageSize = 100;
    const [
      scheduledRequest,
      scheduledRequestTaskBooksResponse,
      scheduleRequestAssetsResponse,
      scheduleResponse,
    ] = await Promise.all([
      apiClient.getScheduleRequest(accountId, scheduledRequestId),
      apiClient.findScheduledRequestTaskbooks(accountId, scheduledRequestId),
      apiClient.findScheduledRequestAssets(accountId, scheduledRequestId, {
        pageSize,
        page,
      }),
      apiClient.findSchedules(accountId, scheduledRequestId),
    ]);

    const workflow = await apiClient.getWorkflow(
      accountId,
      scheduledRequest.workflow.id
    );
    const newScheduledRequest = { ...scheduledRequest, workflow };

    const scheduledRequestTaskBooks: SheculedRequestTaskbooksProps[] =
      scheduledRequestTaskBooksResponse.data.map((taskbook) => ({
        ...taskbook,
        isNew: false,
      }));

    const scheduledRequestAssets: SheculedRequestAssetsProps[] =
      scheduleRequestAssetsResponse.data.map((asset) => ({
        ...asset,
        isNew: false,
      }));

    while (page * pageSize < scheduleRequestAssetsResponse.total) {
      page += 1;
      const loadMoreAssets = await apiClient.findScheduledRequestAssets(
        accountId,
        scheduledRequestId,
        {
          pageSize,
          page,
        }
      );
      scheduledRequestAssets.push(
        ...loadMoreAssets.data.map((asset) => ({
          ...asset,
          isNew: false,
        }))
      );
    }
    const schedule: ScheduleProps = scheduleResponse.data.length
      ? { ...scheduleResponse.data[0], isNew: false }
      : { ...initialStateValues.schedule, isNew: true };

    const parsedScheduledRequest =
      newScheduledRequest as unknown as ScheduledRequestProps;

    return {
      scheduledRequest: { ...parsedScheduledRequest },
      scheduledRequestTaskBooks,
      scheduledRequestAssets,
      schedule,
    };
  }
);

export const getScheduledRequestHandler = (
  builder: ActionReducerMapBuilder<ScheduleRequestSliceState>
) => {
  builder.addCase(getScheduledRequestFormValues.fulfilled, (state, action) => {
    state.isLoading = true;
    state.scheduledRequest = action.payload.scheduledRequest;
    state.scheduledRequestTaskBooks = action.payload.scheduledRequestTaskBooks;
    state.schedule = {
      ...action.payload.schedule,
      startDate: action.payload.schedule.startDate || "",
    };

    state.scheduledRequestAssets = action.payload.scheduledRequestAssets;
    state.scheduledRequestCopy = action.payload.scheduledRequest;
    state.isLoading = false;
    state.willRequestRepeat = true;
    if (action.payload.schedule.startDate === null) {
      const scheduleDefaultStartDate = getNextMonthFirstDay();
      state.schedule.startDate = scheduleDefaultStartDate;
    }
    if (!state.schedule.id) {
      state.willRequestRepeat = false;
      state.schedule.isNew = true;
      state.schedule.timeZone = getTimeZoneIanaFormat();
      state.schedule.startDate = getNextMonthFirstDay();
      state.schedule.executeEvery = 1;
      state.schedule.frequency = "monthly";
      state.isLoading = false;
    }
  });
};
