import {
  ApiRequest,
  ApiEvent,
  ApiInventoryRequest,
  ApiInvoice,
} from "@operations-hero/lib-api-client";
import { OptionProps, GroupBase } from "chakra-react-select";

export type RequestItem = Pick<ApiRequest, "key" | "status" | "summary">;
export type EventItem = Pick<ApiEvent, "key" | "status" | "name">;
export type InventoryRequestItem = Pick<
  ApiInventoryRequest,
  "id" | "inventoryRequestNumber" | "status" | "requester" | "deliveryLocation"
>;
export type InvoiceItem = Pick<
  ApiInvoice,
  "serialNumber" | "status" | "total" | "billingContact" | "id"
>;

export type SearchItem =
  | RequestItem
  | EventItem
  | InventoryRequestItem
  | InvoiceItem;

export type GroupOptions = {
  label: string;
  options: SearchItem[];
};

export type SearchOptionsProps = OptionProps<
  SearchItem,
  false,
  GroupBase<SearchItem>
>;

export const isRequestItem = (item: SearchItem): item is RequestItem => {
  return (item as RequestItem).summary !== undefined;
};
export const isEventItem = (item: SearchItem): item is EventItem => {
  return (item as EventItem).name !== undefined;
};

export const isInventoryRequestItem = (
  item: SearchItem
): item is InventoryRequestItem => {
  return (item as InventoryRequestItem).inventoryRequestNumber !== undefined;
};

export const isInvoiceItem = (item: SearchItem): item is InvoiceItem => {
  return (item as InvoiceItem).serialNumber !== undefined;
};
