import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FieldInputProps, useField, useFormikContext } from "formik";
import { FC } from "react";

export type CheckboxOption = { id: string; name: string };

export interface CheckboxControlProps {
  name: string;
  label?: string;
  value?: boolean;
  multiValue?: string[];
  labelFontWeight?: string;
  isMulti?: Boolean;
  multiOptionWitdh?: string;
  options?: CheckboxOption[];
  defaultValues?: string[];
  disabled?: boolean;
}

export const CheckboxControl: FC<CheckboxControlProps> = ({
  name,
  value,
  label,
  options,
  multiValue,
  disabled,
  isMulti = false,
  labelFontWeight,
  multiOptionWitdh = "max-content",
  defaultValues,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta] = useField({
    name,
    value: isMulti ? multiValue : undefined,
    checked: isMulti ? undefined : value,
  });

  return (
    <FormControl
      isInvalid={!!meta.error && (meta.touched || submitCount > 0)}
      mt={4}
    >
      {isMulti ? (
        <MultiCheckbox
          field={field}
          options={options}
          labelFontWeight={labelFontWeight}
          defaultValues={defaultValues}
          multiOptionWitdh={multiOptionWitdh}
        />
      ) : (
        <SingleCheckbox
          field={field}
          label={label}
          labelFontWeight={labelFontWeight}
          disabled={disabled}
        />
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

interface SingleCheckboxProps
  extends Pick<CheckboxControlProps, "label" | "labelFontWeight" | "disabled"> {
  field: FieldInputProps<any>;
}

const SingleCheckbox: FC<SingleCheckboxProps> = ({
  label,
  field,
  disabled,
  labelFontWeight,
}) => {
  return (
    <Checkbox
      {...field}
      mr={2}
      display="inline-flex"
      isChecked={field.value}
      disabled={disabled}
    >
      {label && (
        <Text fontWeight={labelFontWeight ? labelFontWeight : "300"}>
          {label}
        </Text>
      )}
    </Checkbox>
  );
};

interface MultiCheckboxProps
  extends Pick<
    CheckboxControlProps,
    "options" | "labelFontWeight" | "multiOptionWitdh" | "defaultValues"
  > {
  field: FieldInputProps<any>;
}

const MultiCheckbox: FC<MultiCheckboxProps> = ({
  options,
  field,
  labelFontWeight,
  multiOptionWitdh,
}) => {
  return (
    <Wrap spacing={6}>
      <CheckboxGroup value={field.value}>
        {options && options.length > 0
          ? options.map((item, idx) => (
              <WrapItem
                key={item.id + idx}
                px={2}
                w={multiOptionWitdh}
                display="flex"
                alignItems="center"
              >
                <Checkbox
                  name={field.name}
                  onChange={field.onChange}
                  value={item.id}
                >
                  <Text fontWeight={labelFontWeight ? labelFontWeight : "300"}>
                    {item.name}
                  </Text>
                </Checkbox>
              </WrapItem>
            ))
          : null}
      </CheckboxGroup>
    </Wrap>
  );
};
