import {
  Box,
  Image,
  ImageProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { ZoomableImageAttachment } from "./ZoomableImageAttachment";

interface ImageViewerModalProps {
  image: { src?: string; alt: string };
  imageProps?: ImageProps;
}

const ImageViewerModal: FC<ImageViewerModalProps> = ({ image, imageProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Image
        src={image.src}
        alt={image.alt}
        cursor="pointer"
        onClick={
          image.src
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                onOpen();
              }
            : undefined
        }
        {...imageProps}
      />

      {isOpen && image.src && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
          <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />{" "}
          <ModalContent
            w="full"
            h="full"
            m={0}
            p={0}
            position="relative"
            bg="transparent"
          >
            <ModalCloseButton zIndex="overlay" color="white" />
            <ModalBody
              p={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="full"
              overflow="hidden"
            >
              <ZoomableImageAttachment name={image.alt} url={image.src} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default ImageViewerModal;
