import { Box, Flex, Text } from "@chakra-ui/react";
import { InventorySettingsKeys } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AutoSavingSwitch } from "../../components/inputs/AutoSavingSwitch";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { useShowToast } from "../../hooks/showToast";
import {
  getUserOrAccountSettingDefault,
  SettingProps,
} from "../../utils/emailSettingUtils";

export const InventoryHQEmailSettings = ({
  update,
  accountSettings,
  userSettings,
  label,
}: SettingProps) => {
  const { hasInventory } = useProductSubscriptions();
  const showToast = useShowToast();
  const {
    currentUser: user,
    currentAccount,
    isInventoryAdmin,
  } = useAuthentication();
  const [allowInventoryCreatedEmails, setInventoryCreatedEmails] =
    useState(false);
  const [
    allowInventoryRequestFulfilledOrCanceled,
    setAllowInventoryRequestFulfilledOrCanceled,
  ] = useState(false);
  const [
    allowInventoryRequestNeedMyApproval,
    setAllowInventoryRequestNeedMyApproval,
  ] = useState(false);

  const handleSettingSave = useCallback(
    (key: string) => (value: boolean) => {
      return update(currentAccount.id, { [key]: value })
        .then((saved) => {
          showToast("success", "Update notification setting");
          const {
            ALLOW_INVENTORY_REQUEST_CREATED_EMAILS,
            ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS,
            ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS,
          } = InventorySettingsKeys;

          switch (key) {
            case ALLOW_INVENTORY_REQUEST_CREATED_EMAILS:
              setInventoryCreatedEmails(
                saved[ALLOW_INVENTORY_REQUEST_CREATED_EMAILS] as boolean,
              );
              break;
            case ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS:
              setAllowInventoryRequestFulfilledOrCanceled(
                saved[
                  InventorySettingsKeys
                    .ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS
                ] as boolean,
              );
              break;
            case ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS:
              setAllowInventoryRequestNeedMyApproval(
                saved[ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS] as boolean,
              );
              break;
          }
        })
        .catch(() => {
          showToast("error", "Error updating inventory notification setting");
        });
    },
    [currentAccount, showToast, update],
  );

  useEffect(() => {
    setInventoryCreatedEmails(
      getUserOrAccountSettingDefault(
        accountSettings,
        InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_CREATED_EMAILS,
        userSettings,
      ),
    );

    setAllowInventoryRequestFulfilledOrCanceled(
      getUserOrAccountSettingDefault(
        accountSettings,
        InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS,
        userSettings,
      ),
    );

    setAllowInventoryRequestNeedMyApproval(
      getUserOrAccountSettingDefault(
        accountSettings,
        InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS,
        userSettings,
      ),
    );
  }, [user, currentAccount, accountSettings, userSettings]);

  return (
    <>
      {hasInventory && (
        <Flex flexDir="column" gap={[5, 2, 5, 2]} w="full">
          <Text fontSize="lg" fontWeight="bold">
            {label ? label : "InventoryHQ Settings"}
          </Text>

          <Box width="100%">
            <AutoSavingSwitch
              id="allow-inventory-request-created-emails"
              isChecked={allowInventoryCreatedEmails}
              label="Email me when my inventory request has been created"
              onSave={handleSettingSave(
                InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_CREATED_EMAILS,
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-inventory-request-fulfilled-or-cancelled"
              isChecked={allowInventoryRequestFulfilledOrCanceled}
              label="Email me when the status of my inventory request has been fulfilled or cancelled"
              onSave={handleSettingSave(
                InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_FULFILLED_OR_CANCELLED_EMAILS,
              )}
            />
          </Box>
          {isInventoryAdmin && (
            <Box width="100%">
              <AutoSavingSwitch
                id="allow-inventory-request-needs-my-approval"
                isChecked={allowInventoryRequestNeedMyApproval}
                label="Email me when an inventory request requires my approval"
                onSave={handleSettingSave(
                  InventorySettingsKeys.ALLOW_INVENTORY_REQUEST_NEEDS_APPROVAL_EMAILS,
                )}
              />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};
