import {
  Flex,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface EstimatedExpensesProps {
  handlePropertyChange: (estimatedCost: Partial<ApiRequest>) => void;
  isCostSection?: boolean;
  hideLabel?: boolean;
  inputProps?: NumberInputProps;
}

export const EstimatedExpenses: FC<EstimatedExpensesProps> = ({
  handlePropertyChange,
  isCostSection,
  hideLabel,
  inputProps,
}) => {
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const { request, formIsLocked } = useSelector(
    (state: RootState) => state.requestForm
  );
  const format = (cost: any) => `$` + cost;
  const parse = (cost: any) => {
    const costWithNoCurrency = (cost + "").replace(/^\$/g, "");
    const asNumber = Number(costWithNoCurrency);
    return isNaN(asNumber) ? 0 : asNumber;
  };

  const [estimatedCost, setEstimatedCost] = useState<number>(
    request?.estimatedCost || 0
  );

  const handleCostOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (estimatedCost !== request?.estimatedCost) {
      handlePropertyChange({ estimatedCost });
    }
  };

  const handleCostChange = (estimatedCost: any) => {
    setEstimatedCost(parse(estimatedCost));
  };

  useEffect(() => {
    if (!request || !request.estimatedCost) {
      return;
    }
    setEstimatedCost(parse(request.estimatedCost));
  }, [request, setEstimatedCost]);

  return (
    <FormControl id="estimatedCost">
      <Flex
        flexDirection={isCostSection ? "column" : "row"}
        mt={isMobile && isCostSection ? 4 : 0}
        alignContent="space-evenly"
      >
        {!hideLabel && (
          <Text size="md" fontWeight={isCostSection ? "normal" : "bold "}>
            Estimated Cost
          </Text>
        )}
        <Spacer />
        <NumberInput
          w="100%"
          min={0}
          onChange={handleCostChange}
          onBlur={handleCostOnBlur}
          value={format(estimatedCost)}
          alignItems="right"
          isDisabled={formIsLocked}
          inputMode="numeric"
          isValidCharacter={(value) => {
            const validPatter = /^[0-9]*$/;
            return validPatter.test(value);
          }}
          width={40}
          {...inputProps}
        >
          <NumberInputField
            id="estimatedCost"
            textAlign="right"
            _focus={{ shadow: "none" }}
            _invalid={{
              boxShadow: "none",
              borderColor: "none",
            }}
            pr="3"
          />
        </NumberInput>
      </Flex>
    </FormControl>
  );
};
