import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import { ApiItemAdjustmentReason } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { InventoryAuditReasonSelect } from "../selects/InventoryAuditReasonSelect";

export interface InventoryAuditReasonSelectControlProps {
  label: string;
  name: string;
  value: ApiItemAdjustmentReason | null;
  placeholder?: string;
  helperText?: string;
  labelProps?: FormLabelProps;
  shouldResetOnChange?: boolean;
}

export const InventoryAuditReasonSelectControl = ({
  name,
  value,
  label,
  placeholder,
  helperText,
  labelProps,
  shouldResetOnChange,
}: InventoryAuditReasonSelectControlProps) => {
  const { submitCount, resetForm } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (value: ApiItemAdjustmentReason | null) => {
      shouldResetOnChange && resetForm();
      helper.setTouched(true);
      helper.setValue(value || null);
    },
    [helper, resetForm, shouldResetOnChange]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <InventoryAuditReasonSelect {...field} onChange={handleOnChange} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
