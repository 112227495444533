import {
  ApiAccount,
  ApiClient,
  ApiLocationSummary,
  ApiUserSummary,
  ApiWorkflow,
  ApiWorkflowReasonSummary,
  CreateApiScheduledRequest,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getTimeZoneIanaFormat } from "../../../utils/timezone";
import { ScheduleRequestSliceState } from "../schedule-request-form.slice";
import { getNextMonthFirstDay } from "../scheduled-request-helpers";

interface InitScheduledRequestFormProps {
  apiClient: ApiClient;
  currentUser: ApiUserSummary;
  currentAccount: ApiAccount;
}

export const initScheduleRequestFormValues = createAsyncThunk(
  "account/initScheduleRequestValues",
  async (params: InitScheduledRequestFormProps, thunkAPI) => {
    const { currentAccount, currentUser, apiClient } = params;
    const globalState = thunkAPI.getState() as RootState;

    const { workflows } = globalState.localCache;

    const workflowReasons = await apiClient.findWorkflowReasons(
      currentAccount.id,
      workflows[0].requestSlug,
      { pageSize: 100 }
    );

    const initReason = workflowReasons.data.find(
      (reason) => reason.name === "Preventive"
    );

    return {
      workflow: workflows[0],
      location: undefined as unknown as ApiLocationSummary,
      requester: currentUser as ApiUserSummary,
      reason: initReason as ApiWorkflowReasonSummary,
    } as Partial<CreateApiScheduledRequest>;
  }
);

export const initScheduledRequestHandler = (
  builder: ActionReducerMapBuilder<ScheduleRequestSliceState>
) => {
  builder.addCase(initScheduleRequestFormValues.fulfilled, (state, action) => {
    const scheduleDefualtStartDate = getNextMonthFirstDay();

    state.scheduledRequest = {
      ...state.scheduledRequest,
      location: (action.payload.location as ApiLocationSummary) || undefined,
      workflow: (action.payload.workflow as ApiWorkflow) || undefined,
      requester: action.payload.requester as ApiUserSummary,
      reason: action.payload.reason as ApiWorkflowReasonSummary,
    };

    state.schedule.isNew = true;
    state.schedule.timeZone = getTimeZoneIanaFormat();
    state.schedule.startDate = scheduleDefualtStartDate;
    state.schedule.executeEvery = 1;
    state.schedule.frequency = "monthly";
    state.isLoading = false;
  });
};
