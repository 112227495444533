import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Progress,
  Radio,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import { IoDocumentOutline, IoTrashOutline } from "react-icons/io5";
import { MdCheck, MdClose, MdOutlineEdit } from "react-icons/md";
import ImageViewerModal from "../images/ImageViewerModal";
import { Attachment } from "./Attachments";
import { SparkleWithAnimation } from "../icons/Sparkle";

export interface AttachmentFileCardProps {
  progress?: number;
  onDelete?: () => void;
  isDisabled?: boolean;
  displayActions?: boolean;
  defaultPhoto?: Attachment;
  setDefaultPhoto?: (value: Attachment) => void;
  canEditLabel?: boolean;
  attachment: Attachment;
  showData?: boolean;
  showThumbnailEdit?: boolean;
  onUpdateAttachment?: (attachment: Attachment, label: string) => void;
  boxProps?: BoxProps;
}

export const AttachmentFileCard = ({
  attachment,
  defaultPhoto,
  setDefaultPhoto,
  progress,
  onDelete,
  isDisabled,
  displayActions = true,
  canEditLabel,
  showData = true,
  onUpdateAttachment,
  showThumbnailEdit,
  boxProps,
}: AttachmentFileCardProps) => {
  const { created, file, name, type, url, label } = attachment;
  const footerBgColor = useColorModeValue("whiteAlpha.700", "blackAlpha.700");
  const inputBgColor = useColorModeValue("white", "transparent");

  const [displayUrl, setDisplayUrl] = useState(url);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [labelValue, setLabelvalue] = useState(label || "");

  const isImageBlob = useMemo(() => file && url == null, [file, url]);
  const isImage = useMemo(() => (type || "").indexOf("image/") > -1, [type]);
  const isPdf = useMemo(
    () => (type || "").indexOf("application/pdf") > -1,
    [type]
  );
  const isDoc = useMemo(
    () =>
      (type || "").indexOf(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) > -1 ||
      (type || "").indexOf("application/msword") > -1 ||
      (type || "").indexOf("application/x-abiword") > -1,
    [type]
  );
  const isExcel = useMemo(
    () =>
      (type || "").indexOf(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) > -1 || (type || "").indexOf("application/vnd.ms-excel") > -1,
    [type]
  );

  const { iconToDisplay, colorToDisplay } = isPdf
    ? { iconToDisplay: AiOutlineFilePdf, colorToDisplay: "red.500" }
    : isExcel
      ? { iconToDisplay: AiOutlineFileExcel, colorToDisplay: "green.500" }
      : isDoc
        ? { iconToDisplay: AiOutlineFileWord, colorToDisplay: "blue.500" }
        : { iconToDisplay: IoDocumentOutline, colorToDisplay: "gray.400" };

  const overlayButtonProps = useColorModeValue(
    { background: "gray.200", color: "black", hoverBackground: "gray.300" },
    { background: "gray.200", color: "black", hoverBackground: "gray.300" }
  );

  const handleRemoveAttachment: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsDeleting(true);
    }, []);

  const handleDeleteCancel: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsDeleting(false);
    }, []);

  const handleConfirmDelete: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        if (onDelete) onDelete();
        setIsDeleting(false);
      },
      [onDelete]
    );

  const handleFileClick = useCallback(() => {
    // Docs
    if (!url && !file) {
      return;
    }

    if (url) {
      window.open(url, "_blank");
      return;
    }

    if (file) {
      const newUrl = URL.createObjectURL(file);
      window.open(newUrl, "_blank");
    }
  }, [file, url]);

  const handleThumbnail = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (setDefaultPhoto) {
        setDefaultPhoto(attachment);
      }
    },
    [attachment, setDefaultPhoto]
  );

  const handleUpdateAttachmentLabel: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsEditing(true);
    }, []);

  const handleConfirmUpdate: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        onUpdateAttachment && onUpdateAttachment(attachment, labelValue);
        setLabelvalue("");
        setIsEditing(false);
      },
      [attachment, labelValue, onUpdateAttachment]
    );

  const handleCancelUpdate: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsEditing(false);
    }, []);

  const handleOnChangeAttachmentLabel = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setLabelvalue(e.target.value);
    },
    []
  );

  useEffect(() => {
    if (isImageBlob && file) {
      setDisplayUrl(URL.createObjectURL(file));
    }
    return () => {
      if (isImageBlob && url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [isImageBlob, url, file]);

  return (
    <Box
      maxW={"100%"}
      maxH={"100%"}
      w={"170px"}
      h={"170px"}
      position="relative"
      boxShadow={"md"}
      onClick={handleFileClick}
      cursor={"pointer"}
      {...boxProps}
    >
      <Box
        display="flex"
        position="relative"
        maxW="100%"
        width="100%"
        height="100%"
        maxH="100%"
        overflow="hidden"
        borderRadius={"md"}
      >
        {displayUrl && isImage ? (
          // position: absolute;
          // left: 50%;
          // top: 50%;
          // object-fit: contain;
          // image-orientation: none;
          // box-sizing: border-box;

          <ImageViewerModal
            image={{
              src: displayUrl,
              alt: name,
            }}
            imageProps={{
              transform: "translate(-50%, -50%)",
              minH: "100%",
              minW: "100%",
              position: "absolute",
              boxSizing: "border-box",
              left: "50%",
              top: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <Box
            maxW="100%"
            position="absolute"
            boxSizing="border-box"
            left="40%"
            top="40%"
          >
            <Icon
              as={iconToDisplay}
              width={10}
              height={10}
              color={colorToDisplay}
            />
          </Box>
        )}
        {/* Absolutes positioned elements after image so we dont have to set z-index */}
        {progress == null && onDelete ? (
          <Box
            position="absolute"
            width={isDeleting ? "100%" : undefined}
            display={isDeleting ? "flex" : undefined}
            gap={2}
            justifyContent={isDeleting ? "space-between" : undefined}
            top="0"
            right="0"
            height="40"
            p={1}
          >
            {!isDeleting &&
              !isEditing &&
              !isDisabled &&
              displayActions &&
              setDefaultPhoto && (
                <Flex gap={2}>
                  {showThumbnailEdit && (
                    <>
                      <Box
                        bg="whiteAlpha.700"
                        borderRadius="md"
                        p={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleThumbnail(e);
                        }}
                      >
                        <Radio
                          colorScheme="blue"
                          isChecked={defaultPhoto === attachment}
                          onClick={handleThumbnail}
                        >
                          Thumbnail
                        </Radio>
                      </Box>
                      <Spacer />
                    </>
                  )}
                  <Button
                    size="sm"
                    aria-label="remove attachment"
                    onClick={handleRemoveAttachment}
                    bgColor={overlayButtonProps.background}
                    color={overlayButtonProps.color}
                    _hover={{
                      background: overlayButtonProps.hoverBackground,
                    }}
                  >
                    <Icon as={IoTrashOutline} />
                  </Button>
                  {canEditLabel && (
                    <Button
                      size="sm"
                      aria-label="update attachment label"
                      onClick={handleUpdateAttachmentLabel}
                      bgColor={overlayButtonProps.background}
                      color={overlayButtonProps.color}
                      _hover={{
                        background: overlayButtonProps.hoverBackground,
                      }}
                    >
                      <Icon as={MdOutlineEdit} />
                    </Button>
                  )}
                </Flex>
              )}

            {isEditing && (
              <Flex w="100%" gap={6}>
                <Button
                  size="xs"
                  colorScheme="blue"
                  onClick={handleConfirmUpdate}
                >
                  <Icon as={MdCheck} />
                </Button>
                <Spacer />
                <Button
                  size="xs"
                  onClick={handleCancelUpdate}
                  bgColor={overlayButtonProps.background}
                  color={overlayButtonProps.color}
                  _hover={{
                    background: overlayButtonProps.hoverBackground,
                  }}
                >
                  <Icon as={MdClose} />
                </Button>
              </Flex>
            )}

            {isDeleting && (
              <Flex w="100%">
                <Button
                  size="xs"
                  colorScheme="red"
                  onClick={handleConfirmDelete}
                >
                  <Icon as={MdCheck} />
                </Button>
                <Spacer />
                <Button
                  size="xs"
                  onClick={handleDeleteCancel}
                  bgColor={overlayButtonProps.background}
                  color={overlayButtonProps.color}
                  _hover={{
                    background: overlayButtonProps.hoverBackground,
                  }}
                >
                  <Icon as={MdClose} />
                </Button>
              </Flex>
            )}
          </Box>
        ) : null}
        {progress != null ? (
          <Box position="absolute" bottom="52px" width="100%" height="10px">
            <Progress value={progress}></Progress>
          </Box>
        ) : null}

        {showData && (
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            backgroundColor={isEditing ? "transparent" : footerBgColor}
            fontSize="xs"
            p={1}
          >
            {isEditing ? (
              <Input
                autoFocus
                value={labelValue}
                bgColor={inputBgColor}
                onClick={(e: MouseEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                }}
                onChange={handleOnChangeAttachmentLabel}
              />
            ) : (
              <>
                <HStack isTruncated={true}>
                  <Text>{label || name}</Text>
                  {attachment.scannedByAi && (
                    <Box bg="whiteAlpha.700" borderRadius="md" pt={1} pr={2}>
                      <SparkleWithAnimation />
                    </Box>
                  )}
                </HStack>
                <Text isTruncated={true}>
                  {new Date(created).toLocaleString()}
                </Text>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
