import * as yup from "yup";

export const ScheduleRequestFormSchema = yup.object().shape({
  name: yup.string().max(255, "Field is too long").required("Name is required"),
  summary: yup.string().nullable(),
  location: yup.object().required("Location is required"),
  assignees: yup.object().nullable(),
  reportingCategory: yup.object().nullable(),
  reason: yup.object().nullable(),
  workflow: yup.object().required("Workflow is required"),
  estimatedHours: yup.string().nullable(),
  estimatedCost: yup.number().typeError("Must be a number").nullable(),
  requester: yup.object().required("Requester is required"),
});

export const validateScheduleRequestForm = (scheduleRequest: any): boolean => {
  return ScheduleRequestFormSchema.isValidSync(scheduleRequest);
};
