import { Button, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { FaRegCalendarCheck } from "react-icons/fa";
import { TbCalendarClock } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { RequestReminderForm } from "./RequestReminderForm";

export const RequestReminder: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { reminder, request } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { policyMap } = useSelector((state: RootState) => state.localCache);

  const enableReminder = useMemo(() => {
    if (!request) return false;

    const { workflow } = request;

    const policy = policyMap[workflow.id];

    if (
      policy["admin"] ||
      policy["reviewer"] ||
      policy["technician"] ||
      policy["contractor"]
    ) {
      return true;
    }

    return false;
  }, [policyMap, request]);

  if (!enableReminder) return null;

  return (
    <Flex>
      <Button
        gap={2}
        size="sm"
        onClick={onOpen}
        variant="outline"
        colorScheme="blue"
        display="inline-flex"
      >
        <Icon
          boxSize="18px"
          as={reminder.data === null ? TbCalendarClock : FaRegCalendarCheck}
        />
        Reminder
      </Button>

      <AccountModal
        title="Reminder"
        isOpen={isOpen}
        onClose={onClose}
        content={<RequestReminderForm onClose={onClose} />}
      />
    </Flex>
  );
};
