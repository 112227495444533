import {
  ApiClient,
  ApiInventoryCostType,
  ApiInventoryItem,
  ApiInventoryItemSupplier,
  ApiInventoryOrderItem,
  ApiInventoryType,
  ApiPagingInventoryItem,
  ApiVendor,
  CreateApiInventoryOrderItem,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export const itemType: Record<ApiInventoryType, string> = {
  stock: "Stock",
  checkout: "Check In/Out",
  bulkCheckout: "Bulk Check Out",
};

export const itemCostType: Record<ApiInventoryCostType, string> = {
  averageUnitCost: "Average Unit Cost",
  fixedUnitCost: "Fixed Unit Cost",
  lastPurchasePrice: "Last Purchase Price",
};

interface LoadInventoryItems {
  apiClient: ApiClient;
  accountId: string;
  filters?: Partial<ApiPagingInventoryItem>;
  itemId?: string;
}

export interface InventoryItem extends ApiInventoryItem {
  requested: number;
  stock: number;
  idealStock: number;
  supplierSelected: ApiVendor;
}

export interface InventoryOrderItem extends ApiInventoryOrderItem {
  item: ApiInventoryItem;
  image?: string | null;
  stock: number;
  idealStock: number;
  supplierSelected: ApiVendor;
}

export const loadInventoryItems = createAsyncThunk(
  "inventory-items-order/load",
  async (params: LoadInventoryItems, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState)
      .inventoryOrderItemsListSlice;
    const { apiClient, accountId } = params;
    const newFilters = params.filters
      ? { ...filters, ...params.filters }
      : filters;

    const mapFilters: ApiPagingInventoryItem = {
      ...newFilters,
      catalog: Array.isArray(newFilters.catalog)
        ? newFilters.catalog.map((cat) =>
            typeof cat === "string" ? cat : cat.id
          )
        : undefined,
      storageLocation: Array.isArray(newFilters.storageLocation)
        ? newFilters.storageLocation.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      category: Array.isArray(newFilters.category)
        ? newFilters.category.map((item) =>
            typeof item === "string" ? item : item.id
          )
        : undefined,
      supplier: Array.isArray(newFilters.supplier)
        ? newFilters.supplier.map((supp) =>
            typeof supp === "string" ? supp : supp.id
          )
        : undefined,
      search: params.itemId ? params.itemId : newFilters.search,
    };

    const response = await apiClient.findInventoryItem(accountId, mapFilters);
    return { ...response };
  }
);

export const DEFAULT_FILTERS_INVENTORY: ApiPagingInventoryItem = {
  page: 1,
  pageSize: 20,
  search: "",
  type: undefined,
  category: undefined,
  includeInactive: false,
  storageLocation: undefined,
  catalog: undefined,
  hasSupplier: true, // supplier is needed for reorder but optional on form item
  allowRequesting: true, //if not requestable can't be restocked neither in reorder?
  showLowStock: true, //always low stock on order list in mockups
};

export interface InventoryItemListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: InventoryItem[];
  total: number;
  filters: ApiPagingInventoryItem;
  selectedItemId: string | null;
  isOpenChangeQuantityModal: boolean;
  isOpenCheckoutModal: boolean;
  isOpenCheckoutTableModal: boolean;
  isOpenCheckinModal: boolean;
  isFromQR: boolean;
  workingItem: ApiInventoryItem | null;
  cartOrderItems: {
    supplierId: string;
    isFormOpen: boolean;
    items: InventoryOrderItem[];
  };
}

const DEFAULT_INVENTORY_LIST: InventoryItemListSlice = {
  loading: "idle",
  data: [],
  total: 0,
  selectedItemId: null,
  filters: { ...DEFAULT_FILTERS_INVENTORY },
  isOpenChangeQuantityModal: false,
  isOpenCheckoutModal: false,
  isOpenCheckoutTableModal: false,
  isOpenCheckinModal: false,
  isFromQR: false,
  workingItem: null,
  cartOrderItems: {
    supplierId: "",
    isFormOpen: false,
    items: [],
  },
};

export const inventoryOrderItemsListSlice = createSlice({
  name: "inventory-order-item-list",
  initialState: { ...DEFAULT_INVENTORY_LIST } as InventoryItemListSlice,
  reducers: {
    unloadInventoryItemsList: (state: InventoryItemListSlice) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.workingItem = null;
      state.selectedItemId = null;
      state.filters = { ...DEFAULT_FILTERS_INVENTORY };
    },
    updateFilters: (
      state,
      { payload }: PayloadAction<Partial<ApiPagingInventoryItem>>
    ) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    // Order Items
    setIsOpenInventoryOrderForm: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ supplierId: string; isFormOpen: boolean }>
    ) => {
      state.cartOrderItems.isFormOpen = action.payload.isFormOpen;
      state.cartOrderItems.supplierId = action.payload.supplierId;
    },
    addOrderItemToCart: (
      state: InventoryItemListSlice,
      action: PayloadAction<CreateApiInventoryOrderItem>
    ) => {
      const isAlreadyAdded = state.cartOrderItems.items.find(
        (item) =>
          item.item.id ===
          (typeof action.payload.item === "string"
            ? action.payload.item
            : action.payload.item.id)
      );

      if (isAlreadyAdded) return;
      state.cartOrderItems.items.unshift(action.payload as InventoryOrderItem);
    },
    removeOrderItemFromCart: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string }>
    ) => {
      const index = state.cartOrderItems.items.findIndex(
        (item) => item.item.id === action.payload.id
      );

      if (index !== -1) {
        state.cartOrderItems.items.splice(index, 1);
      }
    },
    setItemQuantity: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; requested: number }>
    ) => {
      const { id, requested } = action.payload;
      const index = state.data.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.data[index].requested = requested;
      }
    },

    setItemSupplier: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; supplier: ApiInventoryItemSupplier }>
    ) => {
      const { id, supplier } = action.payload;
      const index = state.data.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.data[index].supplierSelected = supplier.supplier;
        state.data[index].cost = supplier.unitCost || state.data[index].cost;
      }
    },
    setItemPrice: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; unitCost: number }>
    ) => {
      const { id, unitCost } = action.payload;
      const index = state.data.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.data[index].cost = unitCost;
      }
    },
    updateOrderItemQuantityCartItem: (
      state: InventoryItemListSlice,
      action: PayloadAction<{ id: string; requested: number }>
    ) => {
      const { id, requested } = action.payload;
      const index = state.cartOrderItems.items.findIndex(
        (cartItem) => cartItem.item.id === id
      );

      if (index !== -1) {
        state.cartOrderItems.items[index].requested = requested;
      }
    },
    updateInventoryItemFilters: (
      state: InventoryItemListSlice,
      action: PayloadAction<Partial<ApiPagingInventoryItem>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    cleanOrderItemsCart: (state: InventoryItemListSlice) => {
      state.cartOrderItems.items = [];
    },
    cleanOrderSupplierItemsCart: (
      state: InventoryItemListSlice,
      action: PayloadAction<string>
    ) => {
      const supplierId = action.payload;
      state.cartOrderItems.items = state.cartOrderItems.items.filter(
        (it) => it.supplierSelected.id !== supplierId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInventoryItems.fulfilled, (state, action) => {
      const itemList = action.payload.data;
      state.data = itemList.map((item) => {
        const stock = item.storageLocations.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );
        const idealStock = item.storageLocations.reduce(
          (acc, curr) => acc + (curr.levels?.idealStock || 0),
          0
        );
        const requested = item.storageLocations.reduce(
          (acc, curr) => acc + (curr.levels?.minOrder || 0),
          0
        );
        const supplier = item.supplier[0];
        return {
          ...item,
          requested,
          stock,
          idealStock,
          orderQty: idealStock - stock,
          supplierSelected: supplier.supplier,
          unitCost: supplier.unitCost,
        };
      });
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadInventoryItems.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInventoryItems.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadInventoryItemsList,
  setItemQuantity,
  setItemPrice,
  setIsOpenInventoryOrderForm,
  addOrderItemToCart,
  removeOrderItemFromCart,
  cleanOrderItemsCart,
  updateOrderItemQuantityCartItem,
  updateInventoryItemFilters,
  updateFilters,
  cleanOrderSupplierItemsCart,
  setItemSupplier,
} = inventoryOrderItemsListSlice.actions;

export default inventoryOrderItemsListSlice.reducer;
