import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  StackDivider,
  StackItem,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiInventoryItem,
  ApiInventoryType,
} from "@operations-hero/lib-api-client";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  setIsFromQRModal,
  setIsOpenChangeQuantityModal,
  setIsOpenCheckoutModal,
  setWorkingItem,
} from "../../../store/inventory/inventory-item-list.slice";

interface QrLookupProps {
  item: ApiInventoryItem;
  onCloseQRLookup: () => void;
}

const QrLookup: FC<QrLookupProps> = ({ item, onCloseQRLookup }) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [itemAttachments, setItemAttachments] = useState<ApiAttachment[]>();
  const emailColor = useColorModeValue("blue.600", "blue.900");

  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isInventoryStandard, isInventoryAdmin } = useAuthentication();

  const getItemAttachments = useCallback(() => {
    apiClient
      .findInventoryItemAttachments(currentAccount.id, item.id)
      .then((response) => setItemAttachments(response.data));
  }, [apiClient, item.id, currentAccount.id]);

  const handleRequestItem = useCallback(() => {
    navigate(`/inventory/new-request/`, { state: { itemId: item.name } });
    onCloseQRLookup();
  }, [navigate, onCloseQRLookup, item]);

  const handleEditItem = useCallback(() => {
    navigate(`/inventory/items/${item.id}`);
    onCloseQRLookup();
  }, [navigate, onCloseQRLookup, item.id]);

  const handleChangeQuantity = useCallback(() => {
    dispatch(setIsOpenChangeQuantityModal(true));
    dispatch(setIsFromQRModal(true));
    onCloseQRLookup();
  }, [dispatch, onCloseQRLookup]);

  const handleCheckoutItem = useCallback(() => {
    dispatch(setIsOpenCheckoutModal(true));
    dispatch(setIsFromQRModal(true));
    onCloseQRLookup();
  }, [dispatch, onCloseQRLookup]);

  useEffect(() => {
    getItemAttachments();
  }, [getItemAttachments]);

  useEffect(() => {
    dispatch(setWorkingItem(item));
  }, [dispatch, item]);

  return (
    <VStack width="100%" divider={<StackDivider />}>
      <Flex w="100%" alignItems="center" gap={2}>
        <Image
          boxSize="40px"
          src={itemAttachments ? itemAttachments[0]?.url : undefined}
        />
        <Flex flexDir="column" justifyContent="center">
          <Text fontWeight="bold">{item.name}</Text>
          {item.identifiers.externalId && (
            <Text>#{item.identifiers.externalId}</Text>
          )}
        </Flex>
      </Flex>
      <StackItem width="100%">
        <HStack width="100%" justifyContent="space-between">
          <Text fontWeight="light">Item Number</Text>
          <Text fontWeight="bold">#{item.identifiers.externalId}</Text>
        </HStack>
        <HStack width="100%" justifyContent="space-between">
          <Text fontWeight="light">Part Number</Text>
          <Text fontWeight="bold">#{item.identifiers.mpn}</Text>
        </HStack>
      </StackItem>
      <StackItem width="100%">
        <HStack width="100%" justifyContent="space-between">
          <Text fontWeight="light">Location</Text>
          <Text fontWeight="light">Quantity</Text>
        </HStack>
        {item.storageLocations.map((l, index) => (
          <Fragment key={`inventoryItemLocation::${l.id}`}>
            {(selectAll || index < 3) && (
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="bold">{l.storageLocation.name}</Text>
                <Text fontWeight="bold">{l.quantity}</Text>
              </HStack>
            )}
          </Fragment>
        ))}
        {item.storageLocations.length > 3 && (
          <HStack
            width="100%"
            justifyContent="center"
            _hover={{ textDecoration: "underline", cursor: "pointer" }}
            color={emailColor}
            fontSize="sm"
            onClick={() => setSelectAll(!selectAll)}
          >
            <Text>{selectAll ? "Show less" : "Show All locations"}</Text>
            <Icon as={selectAll ? IoIosArrowUp : IoIosArrowDown} mr={1} />
          </HStack>
        )}
      </StackItem>
      <StackItem width="100%">
        <HStack
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text fontWeight="light">Primary Supplier</Text>
          <Box>
            <Text fontWeight="semibold">
              {item.supplier[0]?.supplier?.primaryContact?.name}
            </Text>
            <Link
              textColor={emailColor}
              href={`mailto:${item.supplier[0]?.supplier?.primaryContact?.email}`}
            >
              {item.supplier[0]?.supplier?.primaryContact?.email}
            </Link>
            <Text>{item.supplier[0]?.supplier?.primaryContact?.phone}</Text>
          </Box>
        </HStack>
      </StackItem>
      <VStack width="100%">
        {(isInventoryStandard || isInventoryAdmin) && (
          <Button colorScheme="blue" width="100%" onClick={handleRequestItem}>
            Request Item
          </Button>
        )}
        {isInventoryAdmin && (
          <>
            <Button
              colorScheme="blue"
              width="100%"
              onClick={handleChangeQuantity}
            >
              Change quantity
            </Button>
            {item.type !== ApiInventoryType.stock && (
              <Button
                colorScheme="blue"
                width="100%"
                onClick={handleCheckoutItem}
              >
                {item.type === ApiInventoryType.bulkCheckout
                  ? "Bulk Check Out"
                  : "Check Out"}
              </Button>
            )}
            <Button colorScheme="blue" width="100%" onClick={handleEditItem}>
              Edit Item
            </Button>
          </>
        )}
        <Button colorScheme="blue" width="100%" onClick={onCloseQRLookup}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
};

export default QrLookup;
