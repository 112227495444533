import {
  ApiClient,
  InventoryRequestSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  inventoryRequestDefaultFilters,
  InventoryRequestFiltersValues,
} from "../../pages/inventory/inventory-request/inventory-request-list/iventoryRequestDefault";

interface LoadInventoryRequestsParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: Partial<InventoryRequestFiltersValues>;
}

export const loadInventoryRequests = createAsyncThunk(
  "iventory-request/load",
  async (params: LoadInventoryRequestsParams, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { filters } = (ThunkAPI.getState() as RootState).inventoryRequest;
    const newFilters = params.filters
      ? {
          ...filters,
          ...params.filters,
          catalog: Array.isArray(params.filters.catalog)
            ? params.filters.catalog.map((item) =>
                typeof item === "string" ? item : item.id
              )
            : undefined,
        }
      : filters;
    const response = await apiClient.findInventoryRequests(
      accountId,
      newFilters
    );
    return response;
  }
);

interface InventoryRequestListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: InventoryRequestSummary[];
  total: number;
  filters: Partial<InventoryRequestFiltersValues>;
  selectedRequests: string[];
}

const DEFAULT_INVENTORY_REQUEST: InventoryRequestListSlice = {
  loading: "idle",
  data: [],
  total: 0,
  filters: { ...inventoryRequestDefaultFilters },
  selectedRequests: [],
};

export const inventoryRequestListSlice = createSlice({
  name: "inventory-request",
  initialState: { ...DEFAULT_INVENTORY_REQUEST },
  reducers: {
    unloadInventoryRequest: (state: InventoryRequestListSlice) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = { ...inventoryRequestDefaultFilters };
    },
    updateInventoryRequestFilters: (
      state: InventoryRequestListSlice,
      action: PayloadAction<Partial<InventoryRequestFiltersValues>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
      state.selectedRequests = [];
    },
    handleSelectAllRequests: (state: InventoryRequestListSlice) => {
      state.selectedRequests = state.data.map((request) => request.id);
    },
    handleClearSelectedRequest: (state: InventoryRequestListSlice) => {
      state.selectedRequests = [];
    },
    handleOnSelectRequest: (
      state: InventoryRequestListSlice,
      action: PayloadAction<string>
    ) => {
      const index = state.selectedRequests.findIndex(
        (id) => id === action.payload
      );
      const selectedRequestsCopy = [...state.selectedRequests];
      if (index !== -1) {
        selectedRequestsCopy.splice(index, 1);
        state.selectedRequests = selectedRequestsCopy;
        return;
      }
      selectedRequestsCopy.push(action.payload);
      state.selectedRequests = selectedRequestsCopy;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInventoryRequests.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadInventoryRequests.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInventoryRequests.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadInventoryRequest,
  updateInventoryRequestFilters,
  handleOnSelectRequest,
  handleSelectAllRequests,
  handleClearSelectedRequest,
} = inventoryRequestListSlice.actions;

export default inventoryRequestListSlice.reducer;
