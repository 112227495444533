import {
  Box,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor } from "@operations-hero/react-draft-wysiwyg";
import "@operations-hero/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "../../../utils/debounce";
import "./rich-text-editor-styles.css";
import { editorToolBar } from "./RichTextEditorControl";

interface TextEditorControlProps {
  id: string;
  label?: string;
  value: string;
  name?: string;
  initialValue?: string | null;
  maxCharacters?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  onBlurNull?: (value: string | null | undefined) => void;
}

const getEditorValueAsString = (newContent: EditorState) => {
  let valueAsMarkDownFormat = "";
  if (newContent) {
    valueAsMarkDownFormat = draftToMarkdown(
      convertToRaw(newContent?.getCurrentContent())
    );
    return valueAsMarkDownFormat;
  }
};

export const TextEditorAutoSave: React.FC<TextEditorControlProps> = ({
  id,
  label,
  name,
  value,
  onBlur,
  onChange,
  initialValue,
  maxCharacters,
  isDisabled,
  isRequired,
  onBlurNull,
}) => {
  const [content, setContent] = useState<EditorState>();
  const [init, setInit] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeClass = useColorModeValue(
    "light-theme-rich-text-editor",
    "dark-theme-rich-text-editor"
  );
  const borderColor = useColorModeValue("#E2E8F0", "#4A5568");
  const focusColor = useColorModeValue("#3182CE", "#63B3ED");

  const wrapperStyle = useCallback(
    () => ({
      borderColor: isOpen ? "transparent" : borderColor,
      boxShadow: isOpen
        ? `0px 0px 0px 2px ${focusColor}`
        : "0px 0px 0px 2px transparent",
    }),
    [isOpen, borderColor, focusColor]
  );

  const handleOnChangeParentState = useCallback(
    (newContent: EditorState) => {
      if (onChange) {
        const editorStateAsString = newContent
          ? getEditorValueAsString(newContent)
          : null;

        onChange(editorStateAsString || "");
      }
    },
    [onChange]
  );

  const debouncedChangeParentState = debounce(handleOnChangeParentState, 250);

  const handleOnChange = (newContent: EditorState) => {
    if (content !== newContent) {
      setContent(newContent);
    }
    onChange && debouncedChangeParentState(newContent);
  };

  const handleOnBlur = useCallback(() => {
    onClose();
    const editorStateAsString = content
      ? getEditorValueAsString(content)
      : null;
    if (editorStateAsString && editorStateAsString !== value) {
      onBlur && onBlur(editorStateAsString);
    } else {
      if (onBlurNull) {
        onBlurNull(editorStateAsString);
        if (isRequired) setInit(undefined);
      }
    }
  }, [content, onBlur, onBlurNull, value, onClose, isRequired]);

  useEffect(() => {
    if (initialValue && !init) {
      const convertedValue = EditorState.createWithContent(
        convertFromRaw(markdownToDraft(initialValue))
      );
      setContent(convertedValue);
      setInit(initialValue);
    }
  }, [init, initialValue]);

  return (
    <FormControl>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <Box className={themeClass}>
        <Editor
          onFocus={onOpen}
          editorState={content}
          onBlur={handleOnBlur}
          toolbar={editorToolBar}
          wrapperStyle={wrapperStyle()}
          readOnly={isDisabled}
          onEditorStateChange={handleOnChange}
        />
      </Box>
      {maxCharacters && (
        <Text fontSize="sm" color="gray.500">
          {maxCharacters} character max
        </Text>
      )}
    </FormControl>
  );
};
