import { CreateApiScheduledRequest } from "@operations-hero/lib-api-client";
import { ScheduledRequestProps } from "./schedule-request-form.slice";

const FIRST_DAY = 1;
const DEFAULT_HOUR = 23;
const DEFAULT_MINUTE = 45;

export const mapSaveScheduledRequest = (
  scheduleRequest: ScheduledRequestProps
) => {
  const { location, workflow, requester } = scheduleRequest;
  if (
    location !== undefined &&
    workflow !== undefined &&
    requester !== undefined
  ) {
    const newScheduledRequest: CreateApiScheduledRequest = {
      name: scheduleRequest.name,
      summary: scheduleRequest.summary,
      generateRequestPerAsset: scheduleRequest.generateRequestPerAsset,
      generateOnlyWhenRequestsAreClosed:
        scheduleRequest.generateOnlyWhenRequestsAreClosed,
      generateByCompletionDate: scheduleRequest.generateByCompletionDate,
      startDaysAfterCreate: scheduleRequest.startDaysAfterCreate,
      dueDaysAfterStart: scheduleRequest.dueDaysAfterStart,
      location: location.id,
      assignees: scheduleRequest.assignees || null,
      reportingCategory: scheduleRequest.reportingCategory,
      reason: scheduleRequest.reason,
      workflow: workflow.id,
      estimatedHours: scheduleRequest.estimatedHours,
      estimatedCost: scheduleRequest.estimatedCost,
      requester: requester.id,
      budget: scheduleRequest.budget,
    };

    return newScheduledRequest;
  }
  return null;
};

export const getNextMonthFirstDay = () => {
  const currentDate = new Date();
  const scheduleStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    FIRST_DAY,
    DEFAULT_HOUR,
    DEFAULT_MINUTE
  ).toString();
  return scheduleStartDate;
};
