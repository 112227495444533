import { Box } from "@chakra-ui/react";
import {
  ApiAccountUser,
  ApiLocationSummary,
  ApiUserSummary,
  ApiWorkflow,
  ApiWorkflowRole,
} from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { MultiValueGenericProps } from "react-select";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  UserOptionsProps,
} from "./select-overrides-types";

export interface RequesterAutocompleteProps {
  value: ApiUserSummary;
  workflow: ApiWorkflow;
  location: ApiLocationSummary;
  onChange: (user: ApiUserSummary | null) => void;
  name?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
}

const RequesterOptionComponent = createOptionComponent(UserBadge);

function CustomSingleValueComponent(
  props: MultiValueGenericProps<ApiAccountUser>,
) {
  const { data, innerProps } = props;

  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
}

export const RequesterAutocomplete = ({
  workflow,
  onChange,
  name,
  value,
  isDisabled,
  isInvalid,
  location,
}: RequesterAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: ApiUserSummary | null) => {
      onChange(newValue);
    },
    [onChange],
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      if (workflow.allowUsersToSubmitRequests) {
        return apiClient
          .findAccountUsers(currentAccount.id, {
            search: inputValue,
            pageSize: 10,
          })
          .then((results) => {
            const data = results.data.map<ApiUserSummary>((user) => {
              const {
                id,
                firstName,
                lastName,
                profileImage,
                phone,
                email,
                timeZone,
              } = user;

              return {
                id,
                firstName,
                lastName,
                profileImage,
                phone,
                email,
                timeZone,
              };
            });
            cb(data);
            return data;
          });
      }

      return apiClient
        .findWorkflowPolicies(currentAccount.id, workflow.id, {
          search: inputValue,
          pageSize: 10,
          type: "user",
          location: location ? location.id : undefined,
          role: [ApiWorkflowRole.requester],
        })
        .then((results) => {
          const data = results.data
            .filter((policy) => policy.type === "user")
            // @ts-ignore wtf typescript
            .map<ApiUserSummary>((x) => x.user);
          cb(data);
          return data;
        });
    },
    [apiClient, currentAccount, workflow, location],
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: UserOptionsProps) => RequesterOptionComponent(props),
      SingleValue: (props: MultiValueGenericProps<ApiAccountUser>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      //https://github.com/JedWatson/react-select/issues/1581
      key={`requesterKey:${workflow.id}::${location && location.id}`}
      name={name}
      defaultOptions={true}
      value={value}
      cacheOptions={true}
      loadOptions={loadOptions}
      onChange={handleChange}
      components={components}
      isDisabled={isDisabled}
      isClearable={false}
      isMulti={false}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isInvalid={isInvalid}
      chakraStyles={commonStyles}
    />
  );
};
